import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import TitleBlock from "../components/titleBlockPages"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
var slugify = require('slugify')

const BlogPage = ({ data }) => {
  const pageDataEdges = data.allBlogJson.edges[0].node;
  const blogDataEdges = data.allBlogsJson.edges;
  const reversedBlogDataEdges = [...blogDataEdges].reverse();
  
  return (
    <Layout>
      <Seo
        title="Blog | TKing Associates"
        description="Read the TKing Blog >>"
      />
      <TitleBlock
        pageTitle={pageDataEdges.pageTitle}
        pageSubTitle={pageDataEdges.pageSubTitle}
        pageTitleBody={pageDataEdges.pageTitleBody}
        img={pageDataEdges.pageTitleImg}
      />
      <div className="container">
              <div className="row py-5">
                  {reversedBlogDataEdges !== null &&
                <>   
                    {reversedBlogDataEdges.map((blog, index) => { 
                      const date = new Date(blog.node.publishDate);
                      const formattedDate = `${date.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}`;
                      const link = "/blog/"+slugify(blog.node.blogTitle,{lower:true})
                    return (
                        <div key={index} className="col-6 col-md-4 d-flex flex-column justify-content-center mb-3"
                            data-sal-duration="500"
                            data-sal="slide-up"
                            data-sal-delay="300"
                            data-sal-easing="ease"
                        >
                            <Link to={link}
                            className="text-decoration-none text-secondary ">
                                <GatsbyImage image={getImage(blog.node.blogImg)} alt={blog.node.blogImgAlt} />
                                <h3 className="py-3 fw-light">{blog.node.blogTitle}</h3>
                                <p>{formattedDate}</p>
                            </Link> 
                            <Link to={link} className="btn btn-primary rounded-0 mb-3">Read more</Link>
                        </div>
                        );
                    })}
                </>
                }

        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query MyQuery {
    
    
    allBlogJson {
        edges {
        node {
            pageSubTitle
            pageTitle
            pageTitleBody
            pageTitleImg {
            childImageSharp {
                gatsbyImageData
            }
          }
        }
      }
    }
    allBlogsJson {
        edges {
        node {
            id
            blogTitle
            blogSubTitle
            blogImgAlt
            publishDate
            blogImg {
            childImageSharp {
                gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                    width: 416
                    height:416
                  )
            }
          }
        }
      }
    }
}`
export default BlogPage
